import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { MDBAnimation } from 'mdbreact'
import image from '../images/usi-avatar.png'
import Showdown from 'showdown'

class ColumnOverlay extends React.Component {
  render() {
    const converter = new Showdown.Converter()
    return (
      <>
        <MDBRow className="overlay-box align-items-center">
          <MDBCol lg="6" md="12" className="px-0 px-md-1">
            <MDBAnimation reveal type="fadeInUp">
              <div className="overlay-text">
                <img
                  src={image}
                  className="img-fluid mt-2"
                  alt="UNICOM Government avatar"
                />

                <div
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.cardtitle) }}
                />
                <div
                  className="text-gray-dark"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.cardtext) }}
                />
              </div>
            </MDBAnimation>
          </MDBCol>
          <MDBCol lg="6" md="12">
            <div dangerouslySetInnerHTML={{ __html: this.props.body }} />
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default ColumnOverlay
